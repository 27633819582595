@charset "UTF-8";
.numberCard,
.NumberCardLines {
  height: 112px;
}

.numberCard {
  padding: 24px;
  margin-bottom: 24px;
}
.numberCard .iconWarp {
  font-size: 54px;
  float: left;
}
.numberCard .content {
  width: 100%;
  padding-left: 78px;
}
.numberCard .content .title {
  line-height: 16px;
  font-size: 16px;
  margin-bottom: 8px;
}
.numberCard .content .number {
  line-height: 32px;
  font-size: 24px;
  height: 32px;
  margin-bottom: 6px;
}

.numberCardLines {
  margin-bottom: 24px;
}
.numberCardLines .flexBox {
  display: flex;
}
.numberCardLines .iconWarp {
  color: #fff;
  font-size: 56px;
  padding: 25px;
}
.numberCardLines .content {
  flex: 1;
  padding: 16px;
}
.numberCardLines .content .title {
  line-height: 16px;
  font-size: 16px;
  margin-bottom: 8px;
  height: 16px;
}
.numberCardLines .content .number {
  line-height: 32px;
  font-size: 24px;
  height: 32px;
  margin-bottom: 0;
}

.chart-row {
  margin: 0 -12px;
}

.chart-row [class^='ant-col'] {
  padding: 0 12px;
}

.chart-filter,
.chart-row {
  margin-bottom: 24px;
}

.chart-filter .chart-filter-input {
  margin-right: 20px;
}

.pie-chart-labels {
  text-align: center;
}

.pie-chart-labels .chart-text {
  display: inline-block;
  line-height: 20px;
  margin: 0 12px 12px;
}

.chart-text label {
  padding-left: 8px;
}

.chart-count {
  height: 200px;
  line-height: 200px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.barchart-wrapper .ant-card-body {
  padding-left: 0px;
}

.stats-table-wrapper p {
  font-size: 24px;
  margin-bottom: 0;
}

.textCard.big {
  padding: 10px;
}
.textCard.big .iconWarp {
  margin-top: 16px;
}

table.stats {
  width: 100%;
  margin-top: 16px;
}
table.stats tr td:first-child {
  color: #888;
}
table.stats tr td:last-child {
  text-align: right;
}

.ant-modal-content {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.5);
  background: #fafafa;
}
.ant-modal-content .ant-modal-header {
  padding: 15px 40px;
  border: 0;
  border-radius: 2px;
  background: #f47721;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}
.ant-modal-content .ant-modal-body {
  padding: 30px 40px;
}
.ant-modal-content ul {
  overflow: scroll;
}
.ant-modal-content ul .field-name {
  font-weight: 500;
}
.ant-modal-content ul .field-value {
  color: #f47721;
  padding-left: 10px;
  font-weight: 700;
}
.ant-modal-content .log-box {
  border: 1px solid #f47721;
  padding: 5px;
  margin: 5px;
}
.ant-modal-content .log-box.info {
  border-color: #108ee9;
}
.ant-modal-content .log-box.info .field-value {
  color: #108ee9;
}
.ant-modal-content .log-box.success {
  border-color: #3ccc38;
}
.ant-modal-content .log-box.success .field-value {
  color: #3ccc38;
}
.ant-modal-content .log-box.warning {
  border-color: #f7ce53;
}
.ant-modal-content .log-box.warning .field-value {
  color: #f7ce53;
}
.ant-modal-content .log-box.danger {
  border-color: #ea475d;
}
.ant-modal-content .log-box.danger .field-value {
  color: #ea475d;
}

.ant-row {
  margin-bottom: 16px;
}

.dd-row {
  margin-bottom: 0px;
}

.dd-required label:before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  font-family: SimSun;
  line-height: 1;
  font-size: 12px;
  color: #f15a24;
}

.dd-input {
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  background-color: #fff;
  background-image: none;
  border: none;
  border-radius: 4px;
  transition: all 0.3s;
}

.disabled-inputs .ant-input:not(.dd-input),
.disabled-inputs .ant-select-selection {
  pointer-events: none;
  border: none;
}
.disabled-inputs .ant-btn,
.disabled-inputs .ant-upload,
.disabled-inputs .has-success.has-feedback:after,
.disabled-inputs .ant-select-arrow {
  display: none;
}

.data-label {
  text-transform: uppercase;
}
.small-label,
.data-label {
  font-size: 12px;
  color: #f47721;
}
.data-label.success,
.small-label.success,
.small-label.low {
  color: #3ccc38;
}
.data-label.warning,
.small-label.warning,
.small-label.medium {
  color: #f7ce53;
}
.small-label.danger,
.data-label.danger,
.small-label.high {
  color: #ea475d;
}
.small-label.info,
.data-label.info,
.small-label.veryHigh {
  color: #108ee9;
}

.small-label i,
.pointer {
  cursor: pointer;
}

.bg-white {
  background-color: #fff;
}

.pull-right {
  float: right;
}

.hidden {
  display: none;
}

.ant-menu-item > a {
  display: inline-block;
}

.logo {
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.logo img {
  height: auto;
  width: 60px;
  max-width: 100%;
}

.bordered {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 3px;
}

.ant-layout-footer {
  text-align: center;
  z-index: 0;
}

.ant-menu-horizontal.ant-menu-root {
  border: none;
  background: #fff;
  padding: 0;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
  padding-right: 16px;
  z-index: 1;
}
.ant-menu-horizontal.ant-menu-root .ant-avatar {
  width: 27px;
  height: 27px;
  line-height: 27px;
  font-size: 16px;
  top: 9px;
  margin-right: 8px;
}
.ant-menu-horizontal.ant-menu-root .ant-avatar * {
  line-height: 26px;
}
.ant-menu-horizontal.ant-menu-root:not(.landing) {
  margin-bottom: 24px;
}

.ant-layout-sider {
  position: relative;
  background: #fff;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.02);
  position: fixed;
  height: 100vh;
  left: 0;
  z-index: 2;
}
.ant-layout-sider ul li > a {
  opacity: 1;
  transition: all 0.2s ease;
}
.ant-layout-sider.ant-layout-sider-collapsed ul li > a {
  opacity: 0;
}
.ant-layout-sider.ant-layout-sider-collapsed ul li ul li a {
  opacity: 1;
}
.ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-layout-sider .ant-menu-item {
  line-height: 16px;
  padding: 10px 0;
  height: auto;
}
.ant-layout-sider .ant-menu-sub.ant-menu-inline > .ant-menu-item a,
.ant-layout-sider .ant-menu-item a {
  white-space: normal;
  padding-right: 36px;
}
.ant-layout-sider.ant-layout-sider-collapsed .ant-menu-sub.ant-menu-inline > .ant-menu-item a,
.ant-layout-sider.ant-layout-sider-collapsed .ant-menu-item a {
  white-space: nowrap;
}

.main-wrapper.mn.ant-layout .ant-menu-item.multi-line i {
  position: relative;
  top: -8px;
}

.ant-layout-sider-trigger {
  background: transparent;
}
.ant-layout-sider-trigger i {
  color: #333;
}

.ant-layout,
.ant-layout-footer {
  background: #e5f4f6;
}

.ant-menu-inline,
.ant-menu-vertical {
  border: none;
}

.ant-layout-content {
  margin: 0 24px;
}

.ant-breadcrumb {
  margin: 14px 0 14px 24px;
  float: left;
}

i.success {
  color: green;
}

.preq-form .ant-calendar-picker {
  width: calc(100% - 16px);
}
.preq-form .ant-row.ant-form-item {
  margin-bottom: 10px !important;
}

.multiple-wrapper {
  padding: 8px 0;
}

.ant-card:not(.ant-card-no-hovering):hover {
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  border-color: transparent;
}

.ant-card-head-title {
  font-size: 13px;
  white-space: normal;
}

.ant-checkbox-group.horizontal .ant-checkbox-group-item {
  display: block;
  padding: 5px 0;
}

.margin {
  margin-top: 16px;
}

.table-operations {
  padding-bottom: 16px;
  text-align: right;
}
.table-operations .ant-btn {
  margin-right: 8px;
}
.table-operations a.ant-btn {
  line-height: 26px;
}
.table-operations a.ant-btn .anticon {
  margin-right: 8px;
}
.table-operations .search-btn {
  float: left;
  margin-left: 8px;
}
.table-operations .search-btn .ant-radio-button-wrapper:first-child {
  background-color: #f47721;
  color: #fff;
}

.send-rfq .ant-select-lg {
  width: 164px;
}

.rfq-form-row {
  display: flex;
  overflow: scroll;
}
.rfq-form-row .ant-input {
  width: 164px;
}

.form-table .ant-row.ant-form-item {
  margin: 0;
  display: block;
}
.form-table .ant-input.ant-input-disabled,
.form-table .ant-select-disabled .ant-select-selection,
.form-table .ant-upload-disabled .ant-btn {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.65);
}
.form-table .ant-upload-disabled .ant-btn {
  color: #fff;
  cursor: not-allowed;
}
.form-table .ant-upload-list {
  display: inline-block;
}
.form-table .ant-table-row td {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.right {
  float: right !important;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}

.home-landing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.home-container {
  position: relative;
  margin: 0 auto;
  width: 1000px;
  height: 100vh;
}

#home-quote {
  position: absolute;
  top: -70px;
  right: 0px;
  font-size: 30px;
  background-color: #fff;
  color: #63aada;
  padding: 5px 10px;
  font-weight: bold;
  font-style: italic;
  font-family: 'serif';
}

.home-landing .top {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  padding: 20px 20px 10px;
  -webkit-box-shadow: 0px 5px 19px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 19px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 19px 5px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.home-landing .top .text {
  margin-top: 20px;
  font-size: 15px;
  font-style: italic;
}
.home-landing .top ul {
  overflow: hidden;
  list-style: none;
  padding: 0;
}
.home-landing .top ul li {
  float: left;
  padding: 5px 10px;
  margin-right: 1px;
  background-color: #e37222;
}
.home-landing .top ul li:hover {
  background-color: #e68744;
}
.home-landing .top ul li a {
  display: block;
  color: #fff;
}
.home-landing .top .logo {
  float: right;
}
.home-landing h1 {
  margin: 0 0 20px;
  font-weight: bold;
  font-size: 60px;
  line-height: 50px;
  color: #009aa6;
}
.home-landing h2 {
  font-size: 15px;
  color: #009aa6;
}
.home-landing .bottom {
  position: absolute;
  bottom: 0;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-box-shadow: 0px 5px 19px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 19px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 19px 5px rgba(0, 0, 0, 0.3);
}
.home-landing .desc {
  height: 229px;
  background-color: #009aa6;
  padding: 15px;
}
.home-landing .desc p {
  color: #fff;
  font-size: 12px;
}
.home-landing .footer {
  margin-top: 15px;
  background-color: #1f497d;
  padding: 15px 15px 5px;
}
.home-landing .footer p {
  color: #fff;
}
.home-landing .item {
  display: block;
}
.home-landing .program {
  background-color: #1f497d;
}
.home-landing .calendar {
  background-color: #e37222;
}
.home-landing .bottom .text {
  padding: 10px;
  color: #fff;
  font-size: 13px;
}
.home-landing .bottom h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 15px;
}
.home-landing .bottom img {
  width: 100%;
  height: 190px;
}

.login-card {
  width: 400px;
  padding: 26px 15px 15px 15px;
}
.login-card button {
  width: 100%;
}
.login-card form .ant-form-item:last-child {
  margin-bottom: 0;
}

.main.ant-layout {
  min-height: 100vh;
  transition: margin-left 0.2s ease;
}

.ant-menu.landing {
  line-height: 64px;
}

.logo.landing {
  height: auto;
  margin-left: 16px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #e3e3e3;
}

.ant-pagination {
  margin: 0 auto;
  display: table;
  margin-top: 16px;
  margin-bottom: 16px;
  float: none;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

.ant-form-item-label {
  white-space: normal;
  line-height: 18px;
  margin-bottom: 6px;
  text-align: left;
}

form .ant-input-number {
  width: 100%;
}

.supplier-forms {
  max-width: 1000px;
}
.supplier-forms .ant-card {
  margin-bottom: 16px;
}
.supplier-forms .ant-card .ant-card-body {
  padding-bottom: 0;
}
.supplier-forms .ant-btn-dashed {
  width: 100%;
  display: block;
  margin-top: 12px;
}
.supplier-forms .ant-form h2 {
  margin: 24px 0 8px 0;
}
.supplier-forms .ant-form h2:first-child {
  margin-top: 0;
}
.supplier-forms .audit-question {
  margin-bottom: 24px;
}
.supplier-forms .audit-question .ant-row.ant-form-item {
  margin-bottom: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px;
  word-break: normal;
}

.ant-table-tbody > tr > td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-table-thead > tr > th {
  font-size: 10px;
}

.ant-table-row.highlight {
  background-color: #f2fafb;
}

.ant-table-row.notification {
  background-color: #fdf3d4;
}

.loading {
  min-height: 50vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-pad-bottom .ant-card-body {
  padding-bottom: 0;
}

.rdw-editor-main {
  height: 372px;
}

[class*='dashed-button'] {
  background: #fff;
  border-style: dashed;
}

.dashed-button-big {
  width: 100%;
  margin-top: 16px;
}

.ant-select-tree {
  max-height: 600px;
  overflow-y: auto;
}

.ant-tag {
  margin-bottom: 8px;
}

.upload-btn-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.upload-btn-wrapper:hover .ant-btn {
  cursor: pointer;
  color: #ff9a4d;
  border-color: #ff9a4d;
}
.upload-btn-wrapper:hover .ant-btn span {
  z-index: 0;
  cursor: pointer;
}
.upload-btn-wrapper .ant-btn {
  z-index: 0;
}
.upload-btn-wrapper input[type='file'] {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.suppliers-search {
  width: 200px;
  float: left;
}
.suppliers-search .anticon-close-circle {
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
  font-size: 12px;
}
.suppliers-search .anticon-close-circle:hover {
  color: #999;
}
.suppliers-search .anticon-close-circle:active {
  color: #666;
}

.radio-vertical .ant-radio-wrapper {
  display: block;
  padding: 8px 0;
}

.registration-title {
  max-width: 1000px;
  text-align: center;
  margin-bottom: 24px;
}

.user-register-form .ant-form-item {
  margin-bottom: 10px;
}

.ant-popover-content {
  max-width: 400px;
}
.ant-popover-content ul,
.ant-popover-content ol {
  margin: 8px 0;
  padding-left: 16px;
}

.users-search .ant-input-suffix {
  cursor: pointer;
}

.ant-select-dropdown-menu-item {
  white-space: normal;
}

.report-btn {
  display: block;
  width: 100%;
  margin-top: 24px;
}

.user-phone .ant-input-number-handler-wrap {
  display: none;
}

.evidence-check label {
  position: relative;
  padding-left: 24px !important;
}
.evidence-check label span.ant-checkbox {
  position: absolute;
  top: 6px;
  left: 0;
}
.evidence-check label span {
  padding: 0;
}

.ant-table-thead th span {
  text-transform: capitalize;
}

a.sn {
  font-weight: 600;
  color: #333;
  text-decoration: underline;
}
a.sn:hover {
  text-decoration: none;
}

.ant-upload-list-item-name {
  max-width: 150px;
}

.login-as-form {
  list-style: none;
}
.login-as-form li {
  color: #0099a8;
  cursor: pointer;
}
.login-as-form li:hover {
  text-decoration: underline;
}

.suppliers-count {
  font-size: 30px;
  text-align: center;
}
.suppliers-count small {
  margin: 0 8px;
  color: #ccc;
  font-size: 26px;
  font-weight: 100;
}

table.suppliers-filter {
  width: 100%;
}
table.suppliers-filter .ant-select {
  display: block;
  width: 110px;
}

.card-columns .ant-card {
  margin-bottom: 24px;
}

.tenders-table .anticon {
  position: relative;
  top: 3px;
}

.video-modal .ant-modal-body {
  padding: 0;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

table.mass-email-response tr td {
  border-bottom: 1px solid #ddd;
  padding: 6px 12px;
}
table.mass-email-response tr td:last-child {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.app-name {
  text-align: left;
  margin-bottom: 64px;
  color: #fff;
}

.app-name strong {
  font-size: 55px;
  text-transform: uppercase;
  display: inline-block;
  padding-right: 25px;
}

.app-name span {
  font-size: 22px;
}

.finance-stat {
  display: inline-block;
  margin-right: 10px;
}

.finance-stat .year {
  color: #bbbaba;
}

.finance-stat.not-changed {
  color: #bdbd5f;
}

.finance-stat.up {
  color: green;
}

.finance-stat.up::after {
  content: '↑';
}

.finance-stat.down {
  color: red;
}

.finance-stat.down::after {
  content: '↓';
}

.products-tree-select {
  width: 450px !important;
}

.tender-message-detail .ant-row div {
  margin-bottom: 5px;
}

.box-gallery .bg-img img {
  height: 100vh !important;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* add your IE10-IE11 css here */
  .ant-list-item-meta {
    width: 450px;
  }
}
#LoginMenus {
  margin-top: 0px;
}

/*# sourceMappingURL=main.min.css.map */
